import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <section className="tc">
      <div className="w5 h5 center">
        <Img fluid={data.file.childImageSharp.fluid} />
      </div>
      <p>
        <a href="mailto:info@keystonegt" className="black">info@keystonegt.com</a>
        <br /><br />
        <a href="tel:8324589884" className="black">(832) 458-9884</a>
      </p>
    </section>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    file(relativePath: { eq: "keystone-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 220, maxHeight: 170) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
